//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTLoadCell  extends LCT{
  constructor(data,store) {
    super(data,store);
  }
}

//---------------------------------------------------------------------

@VueStore
class LCTLoadCellDefinition extends LCTDefinition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name);
  }
}

//---------------------------------------------------------------------

export const loadcell = {
  mixins:[lct]
}

//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTLoadCellDefinition(definition,LCTLoadCell,context,name)
}

//---------------------------------------------------------------------

const definition = {
  definition:'loadcell',
  views:{
  },
  collections:{
  },
  
  fields:{
    id:{
      field:'id',
      label:'Name',
      datatype:'id',
      search:true,
      read:true,

      description_field:'name',
      views:{item:true,list:true}
    },
    type:{
      field:'type',
      label:'Type',
      datatype:'text',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    maxWeight:{
      field:'maxWeight',
      label:'Max Weight',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    }
    
  }
}

//---------------------------------------------------------------------







