<template>
    <div  class="lct-temperature-container" :class="sensorClass" :style="style">
      <div class="temp-thermometer">
        
        <div class="thermometer-image">

        </div>

        <div class="thermometer-spectrum">
           <div class="thermometer-pad"></div>
          <div class="thermometer-indicator"></div>
          <div class="thermometer-ball"></div>

        </div>
      </div>
       <div class="temp-id">{{sensor.id}}</div>
       <!-- <div class="temp-type">{{sensor.type}}</div> -->
       <div class="temp-online"></div>       
       <div class="temp-degrees">{{sensor.degrees}}°C</div>
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    sensor:{type:Object,required:true}
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
         temperatureColor() {
          let temp = this.sensor.degrees;
  // Clamp the temperature between -40 and 100
  const minTemp = -40;
  const maxTemp = 100;
  const clampedTemp = Math.max(minTemp, Math.min(temp, maxTemp));
  
  // Map the temperature to a value between 0 and 1
  const normalizedTemp = (clampedTemp - minTemp) / (maxTemp - minTemp);

  // Rainbow colors for interpolation (ROYGBIV)
  const rainbowColors = [
    { r: 148, g: 0, b: 211 },// Violet
    { r: 75, g: 0, b: 130 }, // Indigo
    { r: 0, g: 0, b: 255 },  // Blue (-40°C)
    { r: 0, g: 255, b: 0 },  // Green
    { r: 255, g: 255, b: 0 },// Yellow
    { r: 255, g: 165, b: 0 },// Orange
    { r: 255, g: 0, b: 0 }   // Red (100°C)
  ];

  // Find the two surrounding colors to interpolate between
  const numColors = rainbowColors.length;
  const colorIndex = Math.floor(normalizedTemp * (numColors - 1));
  const color1 = rainbowColors[colorIndex];
  const color2 = rainbowColors[Math.min(colorIndex + 1, numColors - 1)];

  // Calculate the interpolation factor
  const interpolationFactor = (normalizedTemp * (numColors - 1)) - colorIndex;

  // Interpolate between the two colors
  const r = Math.round(color1.r + (color2.r - color1.r) * interpolationFactor);
  const g = Math.round(color1.g + (color2.g - color1.g) * interpolationFactor);
  const b = Math.round(color1.b + (color2.b - color1.b) * interpolationFactor);
  
  // Return the result as an RGB string
  let res =  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  return res;
},
    sensorClass() {
      let classes = [];
      let onlineClass = (this.sensor.Online === 1 ) ? 'online' : 'offline' ;
      classes.push(onlineClass);
      return classes.join(' ');
    },
    style() {
       let temp_color =  `${this.sensor.degrees * 10}px`;
       let temp_value = `${this.sensor.degrees}`;
       return {
        '--temp-rgb-color':this.temperatureColor,
        '--thermometer-image':'url("/img/thermometer.png")',
        '--spectrum-image':'url("/img/spectrum.png")',
        '--temp-value':temp_value,
        '--temp-color':temp_color
        };
    }

  },
  methods:{
  	...mapActions([]),
  	...mapMutations([]),

  }
 }
</script>
<style>

	div.lct-temperature-container{
    display:flex;    
    max-width: 200px;
    min-width:150px;
    margin:5px;
    border: 2px solid var(--temp-rgb-color);
    border-radius: 5px;
    min-height: 150px;
    position: relative;
    padding-top:32px;
  }


  div.lct-temperature-container.online{
  --online-color:green;
}

div.lct-temperature-container.offline{
  --online-color:red;
}

div.lct-temperature-container > div{
  flex:1;
  /* margin:5px; */
  display:flex;
  align-items: center;
  justify-content: center;
}

div.temp-thermometer{
  position:relative;
  
}


div.thermometer-image{
  position:absolute;
  background-image:var(--thermometer-image);
  background-size:contain;
  /* border: 2px solid red; */
  min-height:150px;
  min-width: 70px;
  background-position: bottom center;
  background-repeat:no-repeat;
  z-index:100;
}
  

  div.thermometer-spectrum{
  
    z-index:100;
    position:absolute;
    left:43%;
    bottom:20%;
    width:10%;
    height:62%;
   
    background-position-y:0px;
    background-repeat: no-repeat;
    border-radius: 0px;
    display:flex;
    flex-direction: column;
    background-size:20% 20%;
  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* background-image:var(--spectrum-image); */
  }


div.thermometer-indicator{
    /* border: 1px solid red; */
  flex:calc(var(--temp-value) + 40);
  border-top-left-radius: 5px;
 border-top-right-radius: 5px;
  /* background-image:var(--spectrum-image); */
  background-position-x:calc(var(--temp-color) * -1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color:var(--temp-rgb-color);
  z-index:100;
  /* background-color:var(--temp-rgb-color); */
}

div.thermometer-ball{
  flex:1;
  background-color:var(--temp-rgb-color);
  /* background-image:var(--spectrum-image); */
  flex:none;
  position:relative;
  min-width:300%;
  left:-100%;
  min-height: 22%;
 /* background-position-x:calc(var(--temp-color) * -1); */
  border-radius: 100px;
  position:absolute;
  bottom:-21%;
  /* display:none; */
  z-index:80;
}


div.thermometer-pad{
  flex:calc(140 - var(--temp-value) - 40);
  /* border: 1px solid pink; */
  background-color:white;

}

div.temp-id{
  font-weight: bold;
  border: 2px solid var(--rbg-color);
  background-color:var(--temp-rgb-color);
  color:white;
  position:absolute;
  top:0px;
  left:0px;
  z-index:100;
  right:0px;
  height: 30px;
  text-transform: uppercase;
}

div.temp-online{
  position:absolute;
  min-height: 15px;
  max-height: 15px;
  min-width: 15px;
  max-width: 15px;
  background-color:var(--online-color);
  top:35px;
  right:5px;
  width:15px;
  height:15px;
  border-radius: 15px;
}


</style>


