
import {Definition as root}    from '@/definitions/root.js';
import {Definition as loadcell} from '@/definitions/loadcell.js';
import {Definition as inclinometer} from '@/definitions/inclinometer.js';
import {Definition as user}    from '@/definitions/user.js';
import {Definition as log} from '@/definitions/log.js';
import {Definition as test}    from '@/definitions/test.js';
import {Definition as thermometer} from '@/definitions/thermometer.js';
import {Definition as result} from '@/definitions/result.js';


//---------------------------------------------------------------------

export const definitions = {
  root,
  loadcell,
  inclinometer,
  user,
  log,
  test,
  result,
  thermometer
}
