//---------------------------------------------------------------------

import {Definition,DBObject,definition} from '@/db_interface';

//---------------------------------------------------------------------


export class LCT  extends DBObject{
  constructor(data,store) {
    super(data,store)
  }

}

//---------------------------------------------------------------------

export class LCTDefinition extends Definition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name)  
  }
}

//---------------------------------------------------------------------

export const lct = {
  mixins:[definition],
}

//---------------------------------------------------------------------
