import pluralize from 'pluralize';
import lodash from 'lodash';


//---------------------------------------------------------------------

import {objects_from_path} from './db_utils.js';

import {getDefinition} from './db_definitions_store.js';

//---------------------------------------------------------------------

export const db_proxy_handler = {
  get(target, property) {
    return target[property];
   },
   set(target, property, value) {
     target[property] = value;
     return true;
  }
}

//---------------------------------------------------------------------

const RESERVED_FIELDS = {
  start_date:true,
  sort_value:true,
  sort_field:true,
  hovering:true,
  definition:true,
  description:true,
  search_string:true,
  db_fields:true,
  db_values:true,
  save_timer_duration:true,
  passes_search:true,
  state:true,
  risk:true,
  __state:true,
  sorted_countries:true,
  store:true,
  covid19:true,
  active:true,
  edit_view_fields:true,
  collection_path_tokens:true,
  collection:true,
  document_name:true,
  _hovering:true,
  parents:true
}

//---------------------------------------------------------------------

export class DBObject  {
  constructor(data,store) {
    this.data = data;
    for (let f in data) {
      if (data.hasOwnProperty(f)) { // THIS CHECK WILL NOT BE NECESSARY IF THE FIELDS USE A RESERVED NAMING CONVENTION... 
      if (this[f] === undefined ) {
        this[f] = data[f];
      }
      else 
      {
        // console.warn(`WARNING: UNABLE TO SET FIELD:${f} ON ${data.collection_path}/${data.id} USING _${f} INSTEAD.`)
        this[`_${f}`] = data[f]
      }
    }
  }
    this.__state = store.state;
    this.store = store;
    this.collection_path_tokens = data.collection_path.split('/').filter(function(t) {return t.length > 0});
    this.collection = this.collection_path_tokens[this.collection_path_tokens.length -1 ];
    this.document_name = pluralize.singular(this.collection);
    this._hovering = false;
    
    let parents = objects_from_path(data.collection_path,this.__state);
    for (let p in parents ) {
      let parent = parents[p];
      // ??? SHOULD THIS BE A PROP OR A FUNCTION ???
      this[p] = function() {return parent}
    }
  }


  get active() {
    // return (this.store !== undefined && this.store.state !== undefined && this.store.state.active !== undefined && 
    return (this.store.state?.active?.[this.document_name] === this.id)
  }
  
  
  get sort_value() {
    return lodash.get(this,this.sort_field)
  }
  
  get sort_field() {
    return this.definition.sort_field;
  }
  
  get hovering() {
    return (this._hovering === true)
  }

  set hovering(value) {
    this._hovering = (value === true)
  }
  
  get definition() {
    return getDefinition(this.document_name)
  }
  
  get description() {
    return this[this.definition.description_field_name];
  }
  

  get search_string() {
    let res = [];
    let search_fields = (this.definition && this.definition.search_fields) ? this.definition.search_fields : ['id'];
    for (let f = 0 ; f < search_fields.length ; f ++ ) {
      let field = search_fields[f];
      let value = lodash.get(this,field);
      res.push(`${value}`);
    }
    return res.join(' ').toLocaleLowerCase();
  }

  get db_fields() {
    return this.definition.db_fields;
  }

  get db_values() {
    let res = {};
    for (let f = 0 ; f < this.db_field_names ; f ++ ) {
      let field = this.db_field_names[f];
      res[field] = this.get_value(field);
    }
    return res;
  }
  get save_timer_duration() {
    return this.definition.save_timer_duration === undefined ? 3000 : this.definition.save_timer_duration;
  }
  get passes_search () {
    return (this.definition.search_string === '' || this.search_string.includes(this.definition.search_string))
  }

  get edit_view_fields() {
    let self = this;
    return this.definition.edit_view_fields.filter(function(field) {
      return ((field.views.edit === true) || (typeof field.views.edit === 'function' && field.views.edit(self,self.store)))
    }
  );
}

  get csv_values() {
    let res = [];
    for (let f in this.definition.csv_fields ) {
      let field = this.definition.csv_fields[f];
      let field_name = field.name;
      res.push(`"${this[field_name]}"`);
    }
    return res;
  }
  
  revert() {
    this.definition.db_write_fields.map((f) =>  _.set(this,f.name,_.get(this.data,f.name))  )
  }
  
  
  delay_save() {
    if (this.definition !== undefined) {
      if (!(this.__internal_timer === undefined)) {
        clearTimeout(this.__internal_timer);
        this.__internal_timer = undefined;
      }
      this.__internal_timer = setTimeout(this.do_delay_save,this.save_timer_duration);
    }
  }
  
  do_delay_save() {
    this.__internal_timer = undefined;
    this.save();
  }

  get_value(field) {
    return this[field] === undefined ? this.definition[field].default : this[field];
  }

  get_display_value(field) {
    let value = this.get_value(field);
    let field_def = this.definition[field];
    let dt = field_def.datatype;
    if (dt === 'foreign_key') {
      return value;
    } else 
    if (dt === 'number') {
      return value;
    } else 
    if (dt === 's') {
      return value;
    } else 
    {
      return value;
    }

    // options:{link_field:'id',display_field:'id',collection:`vehicles`},


  }

  create(document_name,record) {
    let nop = function(record) { return record };
    let doc_definition = getDefinition(document_name); 
    let before_create_function = (doc_definition.on_before_create ) ? doc_definition.on_before_create : nop;
    let after_create_function  = (doc_definition.on_after_create  ) ? doc_definition.on_after_create  : nop;
    let mapped_record = before_create_function(record);
    let DocClass = (doc_definition.class) ?  doc_definition.class : Definition ;
    let NewDocument = new DocClass(mapped_record);
    let CreatedDocument = NewDocument.create();
    let UpdatedNewDocument = after_create_function(CreatedDocument);
    return UpdatedNewDocument;
  }

  bind_collection(collection_name) {
    if (this.is_bound_to_collection(collection_name) === false) {
      if (this.collection_state[collection_name] !== undefined && this.collection_state[collection_name].bind !== undefined) {
        this.collection_state[collection_name].bind()
      }
      else
      {
        let collection = this.definition?.collections?.[collection_name] ?? {live:true,collection_path:`${this.collection_path}/${this.id}/${collection_name}`};
        this.store.dispatch('db_get_collection',{collection_path:collection.collection_path,options:{live:collection.live}});
      }
    }
  }

  unbind_collection(collection_name) {
    if (this.collection_state[collection_name] !== undefined && this.collection_state[collection_name].unbind !== undefined) {
      this.collection_state[collection_name].unbind()
    }
  }
  
  is_bound_to_collection(collection_name) {
    return (this.collection_state !== undefined && this.collection_state[collection_name]  !== undefined && this.collection_state[collection_name].bound === true)
  }

  create() {
    this.store.dispatch('db_create_document',{record:this});
    return this;
  }
  select(selected) {
    let fields = {selected};
    this.selected = (selected === true);
    this.update(fields);
  }
  save(opts) {
    let options = opts || {};
    if (this.definition !== undefined) {
      options.definition = this.definition;
      let fields = this.definition.db_write_fields;
      this.update(fields,options);
    }
  }
  update(fields,opts) {
    let use_opts = (opts === undefined) ?  { } : opts;
    this.store.dispatch('db_update_document',{record:this,fields:fields,options:{definition:this.definition,...use_opts}});
  }
  delete() {
    this.store.dispatch('db_flag_delete_document',{collection_path:this.collection_path,id:this.id});
    this.deleted = false;
  }
  activate() {
    this.store.dispatch('db_global_set_active',this);
    this.on_after_activate?.()
  }
  

  get_sort_field(collection) {
    return getDefinition(collection).sort_field;
  }
  get_sort_ascending(collection) {
    return getDefinition(collection).sort_ascending;
  }
  sort_searched_collection(collection) { 
    return this.sort_collection(collection).filter(function(r) { return r.passes_search });
  }
  
  sort_collection(collection) {
    let document_name = pluralize.singular(collection);
    let records = this[collection];
    let res = [];
    for (let r in records) {
      let record = records[r];
      res.push(record);
    }
    let ascending = this.get_sort_ascending(document_name);
    let sort_field = this.get_sort_field(document_name);
    return res.sort(function(a,b) {return internal_sort(a,b,sort_field,ascending) });
  }
}


//---------------------------------------------------------------------
//  INTERNAL FUNCTIONS
//---------------------------------------------------------------------

function internal_sort(a,b,field,ascending) {
  let aval = lodash.get(a,field);
  let bval = lodash.get(b,field);
  let sort_dir = ascending ? 1 : -1;
  return (aval < bval) ? sort_dir : -1*sort_dir;
}

//---------------------------------------------------------------------


