<template>
<div class="lct-inclinometer-container" :style="style" :class="inclinometerClass">
   <div class="inclinometer-id">{{inclinometer.id}}</div>
   <div class="inclinometer-pitch inclinometer-value-label">
  
     <div class="inclinometer-label">X:</div>
     <div class="inclinometer-value">
      {{x}}g
     </div>
    </div>

   <div class="inclinometer-roll inclinometer-value-label">
    <div class="inclinometer-label">Y:</div>
    <div class="inclinometer-value">
    {{y}}g 
    </div>
   </div>

   <div class="inclinometer-yaw inclinometer-value-label">
      <div class="inclinometer-label">Z:</div>
      <div class="inclinometer-value">
        {{z}}g
      </div>
    </div>

   <div class="inclinometer-angle inclinometer-value-label">
      <div class="inclinometer-label">Tilt:</div>
      <div class="inclinometer-value">
        {{angleBetweenVectors}}°
      </div>
    </div>

   <div class="inclinometer-type inclinometer-value-label">
      <div class="inclinometer-value">
        {{inclinometer.type}}
      </div>
    </div>



  <div class="inclinometer-zero inclinometer-value-label">
      <div class="inclinometer-button" @click="doZeroInclinometer">Zero</div>
  </div>



    <div class="scene">
      <div
        class="cube"
        :style="cubeStyle"
      >
        <div class="cube-face front">Front</div>
        <div class="cube-face back">Back</div>
        <div class="cube-face left">Left</div>
        <div class="cube-face right">Right</div>
        <div class="cube-face top">Top</div>
        <div class="cube-face bottom">Bottom</div>

      </div>
    </div>
    </div>
 
</template>

<script>

import {mapState,mapActions} from 'vuex';

function calculateRotationMatrix(refX, refY, refZ) {
    // Target vector to align with (0, 0, 1)
    const targetX = 0, targetY = 0, targetZ = 1;


if (Math.abs(refX) < 1e-6 && Math.abs(refY) < 1e-6 && Math.abs(refZ - 1) < 1e-6) {
        // Return identity matrix if no rotation is needed
        return [
            [1, 0, 0],
            [0, 1, 0],
            [0, 0, 1]
        ];
    }

    // Cross product to find rotation axis
    const axisX = refY * targetZ - refZ * targetY;
    const axisY = refZ * targetX - refX * targetZ;
    const axisZ = refX * targetY - refY * targetX;

    console.log('axisX,axisY,axisZ:',axisX,axisY,axisZ);


    const axisMagnitude = Math.sqrt(axisX * axisX + axisY * axisY + axisZ * axisZ);
    console.log('axisMagnitude:',axisMagnitude);
    const ux = axisX / axisMagnitude;
    const uy = axisY / axisMagnitude;
    const uz = axisZ / axisMagnitude;

    // Dot product to find the rotation angle
    const dot = refX * targetX + refY * targetY + refZ * targetZ;
    const angle = Math.acos(dot);

    // Rodrigues' rotation matrix components
    const cosA = Math.cos(angle);
    const sinA = Math.sin(angle);
    const Rxx = cosA + ux * ux * (1 - cosA);
    const Rxy = ux * uy * (1 - cosA) - uz * sinA;
    const Rxz = ux * uz * (1 - cosA) + uy * sinA;
    const Ryx = uy * ux * (1 - cosA) + uz * sinA;
    const Ryy = cosA + uy * uy * (1 - cosA);
    const Ryz = uy * uz * (1 - cosA) - ux * sinA;
    const Rzx = uz * ux * (1 - cosA) - uy * sinA;
    const Rzy = uz * uy * (1 - cosA) + ux * sinA;
    const Rzz = cosA + uz * uz * (1 - cosA);

    return [
        [Rxx, Rxy, Rxz],
        [Ryx, Ryy, Ryz],
        [Rzx, Rzy, Rzz]
    ];
}

function applyRotation(x, y, z, rotationMatrix) {
    const rotatedX = rotationMatrix[0][0] * x + rotationMatrix[0][1] * y + rotationMatrix[0][2] * z;
    const rotatedY = rotationMatrix[1][0] * x + rotationMatrix[1][1] * y + rotationMatrix[1][2] * z;
    const rotatedZ = rotationMatrix[2][0] * x + rotationMatrix[2][1] * y + rotationMatrix[2][2] * z;

    return {
        x: rotatedX,
        y: rotatedY,
        z: rotatedZ
    };
}

export default {
  props: {  
    inclinometer:{type:Object,required:true},
    angles:{type:Object,required:true},
  },
  data() {
    return {}
  },
  methods:{...mapActions(['db_create_document']),
    doZeroInclinometer() {
      let updateRecord = {collection_path:'/inclinometers',id:this.inclinometer.id,deleted:false};
      updateRecord.zeroRoll = this.inclinometer.roll;
      updateRecord.zeroYaw = this.inclinometer.yaw;
      updateRecord.zeroPitch = this.inclinometer.pitch;
      updateRecord.zeroX = this.inclinometer.accel_x || 0;
      updateRecord.zeroY = this.inclinometer.accel_y || 0;
      updateRecord.zeroZ = this.inclinometer.accel_z || this.gravity;
      
      this.db_create_document({record:updateRecord,options:{on_success:this.onZeroSuccess}})
    },
    onZeroSuccess(params) {
    }
  },
  computed: {
    ...mapState(['inclinometers']),

    angleBetweenVectors() {
      let ax = this.zeroInclinometer.zeroX;
      let ay = this.zeroInclinometer.zeroY;
      let az = this.zeroInclinometer.zeroZ;
      let bx = this.inclinometer.accel_x;
      let by = this.inclinometer.accel_y;
      let bz = this.inclinometer.accel_z;
      
      const dotProduct = ax * bx + ay * by + az * bz;
      const magnitudeA = Math.sqrt(ax * ax + ay * ay + az * az);
      const magnitudeB = Math.sqrt(bx * bx + by * by + bz * bz);
      
      const cosTheta = dotProduct / (magnitudeA * magnitudeB);

      const angleRadians = Math.acos(cosTheta);

      const angleDegrees = angleRadians * (180 / Math.PI);

      return angleDegrees.toFixed(3);

    },

    gravity() {
      return 9.80665; 
    },
 calibrate() {
   return {refX:this.zeroInclinometer.vectorX,refY:this.zeroInclinometer.vectorY,refZ:this.zeroInclinometer.vectorZ}
 },   
 xxx() {
   
  const calibration = this.calibrate;

// Step 2: Transform any subsequent readings based on the calibration
    let x = this.inclinometer.accel_x;
    let y = this.inclinometer.accel_y;
    let z = this.inclinometer.accel_z;

    let refX = calibration.refX;
    let refY = calibration.refY;
    let refZ = calibration.refZ;

    const rotationMatrix = calculateRotationMatrix(refX, refY, refZ);
    console.log('rotationMatrix:',this.inclinometer.id,rotationMatrix);

    const transformed = applyRotation(x, y, z, rotationMatrix);
    console.log('transformed:',this.inclinometer.id,transformed);

    return {
      x:transformed.x,
      y:transformed.y,
      z:transformed.z
    }

    // const magnitude = Math.sqrt(x * x + y * y + z * z);

    // Normalize the current reading
    const normX = x / magnitude;
    const normY = y / magnitude;
    const normZ = z / magnitude;

    // Calculate adjusted readings as the difference from the calibration vector
    const adjustedX = normX - refX;
    const adjustedY = normY - refY;
    const adjustedZ = normZ - refZ;

    return {
        x: adjustedX,
        y: adjustedY,
        z: adjustedZ
    };

 },



  orientation() {
    let x = this.x;
    let y = this.y;
    let z = this.z;
    const toDegrees = radians => radians * (180 / Math.PI);
    
    // Calculate roll and pitch in radians
    const roll = Math.atan2(y, Math.sqrt(x * x + z * z));
    const pitch = Math.atan2(-x, Math.sqrt(y * y + z * z));
    
    // Convert radians to degrees
    const rollDegrees = toDegrees(roll);
    const pitchDegrees = toDegrees(pitch);

    return {
        roll: rollDegrees,
        pitch: pitchDegrees
      };
    },
    zeroInclinometer() {
      let res = {zeroPitch:0,zeroRoll:0,zeroYaw:0,zeroX:0,zeroY:0,zeroZ:this.gravity,id:this.inclinometer.id};
      if (this.inclinometers[this.inclinometer.id] === undefined) { 
        
      }
      else 
      {
        res = {...res,...this.inclinometers[this.inclinometer.id]}
      }
      let x = res.zeroX;
      let y = res.zeroY;
      let z = res.zeroZ;
      res.magnitude =  Math.sqrt(x * x + y * y + z * z);
      res.vectorX = x / res.magnitude;
      res.vectorY = y / res.magnitude;
      res.vectorZ = z / res.magnitude;
      console.log('zeroInclinometer:',this.inclinometer.id,res);
      return res;      
    },
    angle() {
      return   this.angles[this.inclinometer.id]
    },
    x() { return ((this.xxx.x / this.gravity)   ).toFixed(3)},
    y() { return ((this.xxx.y / this.gravity)  ).toFixed(3)},   
    z() { return ((this.xxx.z / this.gravity)  ).toFixed(3)},  
    
    calibrated() {
      let refX = this.zeroInclinometer.zeroX /  this.gravity; 
      let refY = this.zeroInclinometer.zeroY  / this.gravity;
      let refZ = this.zeroInclinometer.zeroZ  / this.gravity;



    // Calculate angle and axis to rotate reference to align with (0, 0, 1)
    const angle = Math.acos(refZ); // Angle between ref vector and (0, 0, 1)
    const sinAngle = Math.sin(angle);
    const cosAngle = Math.cos(angle);
    
    // Axis of rotation (cross product of ref vector and (0, 0, 1))
    const axisX = -refY;
    const axisY = refX;
    const axisZ = 0;

    // Normalize the axis
    const axisMagnitude = Math.sqrt(axisX * axisX + axisY * axisY + axisZ * axisZ);
    console.log('axisMagnitude:',this.inclinometer.id,axisMagnitude);
    const ux = axisX / axisMagnitude;
    const uy = axisY / axisMagnitude;
    const uz = axisZ / axisMagnitude;

    // Rotation matrix components based on Rodrigues' rotation formula
    let rotationMatrix =  [
        [
            cosAngle + ux * ux * (1 - cosAngle),
            ux * uy * (1 - cosAngle) - uz * sinAngle,
            ux * uz * (1 - cosAngle) + uy * sinAngle
        ],
        [
            uy * ux * (1 - cosAngle) + uz * sinAngle,
            cosAngle + uy * uy * (1 - cosAngle),
            uy * uz * (1 - cosAngle) - ux * sinAngle
        ],
        [
            uz * ux * (1 - cosAngle) - uy * sinAngle,
            uz * uy * (1 - cosAngle) + ux * sinAngle,
            cosAngle + uz * uz * (1 - cosAngle)
        ]
    ];
    
    console.log('rotationMatrix:',this.inclinometer.id,rotationMatrix);

     return {
        x: rotationMatrix[0][0] * refX + rotationMatrix[0][1] * refY + rotationMatrix[0][2] * refZ,
        y: rotationMatrix[1][0] * refX + rotationMatrix[1][1] * refY + rotationMatrix[1][2] * refZ,
        z: rotationMatrix[2][0] * refX + rotationMatrix[2][1] * refY + rotationMatrix[2][2] * refZ
    };

    },


    calibratedX() {
      let x = this.inclinometer.accel_x / this.gravity;
      let y = this.inclinometer.accel_y  / this.gravity;
      let z = this.inclinometer.accel_z  / this.gravity;
      console.log('x,y,z:',x,y,z);
      let initialX = this.zeroInclinometer.zeroX /  this.gravity; 
      let initialY = this.zeroInclinometer.zeroY  / this.gravity;
      let initialZ = this.zeroInclinometer.zeroZ  / this.gravity;
    const dot = initialZ * 1; // Aligning with the "upright" Z-axis
    const angle = Math.acos(dot);

    // If already upright, return the input values
    if (angle === 0) return { x: x, y: y, z: z };

    // Axis of rotation (normalized cross product of initial vector and (0,0,1))
    const crossX = initialY;
    const crossY = -initialX;
    const crossZ = 0;
    const crossMagnitude = Math.sqrt(crossX * crossX + crossY * crossY + crossZ * crossZ);

    // Normalized rotation axis
    const ux = crossX / crossMagnitude;
    const uy = crossY / crossMagnitude;
    const uz = crossZ / crossMagnitude;

    // Rotation matrix components based on Rodrigues' rotation formula
    const cosA = Math.cos(angle);
    const sinA = Math.sin(angle);
    const Rxx = cosA + ux * ux * (1 - cosA);
    const Rxy = ux * uy * (1 - cosA) - uz * sinA;
    const Rxz = ux * uz * (1 - cosA) + uy * sinA;
    const Ryx = uy * ux * (1 - cosA) + uz * sinA;
    const Ryy = cosA + uy * uy * (1 - cosA);
    const Ryz = uy * uz * (1 - cosA) - ux * sinA;
    const Rzx = uz * ux * (1 - cosA) - uy * sinA;
    const Rzy = uz * uy * (1 - cosA) + ux * sinA;
    const Rzz = cosA + uz * uz * (1 - cosA);

    // Apply rotation matrix to new readings
    const virtualX = Rxx * x + Rxy * y + Rxz * z;
    const virtualY = Ryx * x + Ryy * y + Ryz * z;
    const virtualZ = Rzx * x + Rzy * y + Rzz * z;

    let res = {
        x: virtualX,
        y: virtualY,
        z: virtualZ
    };    
    console.log('calibrated:',res);
    return res;

    },
    magnitude() {
      return Math.sqrt(this.x**2 + this.y**2 + this.z**2).toFixed(3);
    },
    vectorAngle() {
      return this.angles[this.inclinometer.id].vectorAngle.toFixed(3);
    },
    inclinometerClass() {
      let classes = [];
      let onlineClass = (this.inclinometer.Online === 1) ? 'online' : 'offline' ;
      classes.push(onlineClass);
      return classes.join(' ');
    },
    pitch() {
      return this.orientation.pitch;
    },
    roll() {
      return this.orientation.roll;
    },
    style() { return {} },
    cubeStyle() {
      return {
        transform: `rotateX(${this.pitch}deg) rotateY(${this.roll}deg) rotateZ(0deg)`,
      };
    },
  },
  mounted() {
    // Simulate accelerometer data updates
  },
};
</script>

<style scoped>

div.lct-inclinometer-container{
   border: 2px solid var(--online-color);
   position:relative;
   display:flex;
   min-height: 100px;   
   margin:5px;
   max-width:300px;
   min-width:300px;
   border-radius: 5px;
   padding:5px;
   --pitch-color:#007E33;
   --roll-color:#CC0000;
   --yaw-color:#0099CC;
   --zero-color:#186CEF;
}

div.inclinometer-id{
  flex:3;
  min-width: 150px;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  background-color:var(--online-color);
  color:white;
  font-weight: bold;
  text-transform: uppercase;
}

div.inclinometer-pitch{
  top:30px;
  color:var(--pitch-color);
}

div.inclinometer-roll{
  top:60px;
  color:var(--roll-color);
}

div.inclinometer-yaw{
  top:90px;
  color:var(--yaw-color);
}

div.inclinometer-angle{
  top:120px;
  color:var(--angle-color);
}




div.inclinometer-value-label{
  position: absolute;
  right:5px;
  display:flex;
  max-height:25px;
  min-height: 25px;
  align-items:center;
  min-width: 100px;
}

div.inclinometer-zero{
  top:180px;
  color:var(--button-color);
  text-align: center;
  display:flex;
}


div.inclinometer-button{
  flex:1;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--zero-color);
  font-weight: bold;
  background-color:var(--zero-color);
  color:white;
  box-shadow: 2px 2px 2px #CCC;
}


div.inclinometer-value{
  font-weight: bold;
  flex:1;
  text-align: right;
}

div.inclinometer-value{
  flex:1;
}

div.lct-inclinometer-container.online{
  --online-color:#178B4D;
}

div.lct-inclinometer-container.offline{
  --online-color:#C42511;
}


/* 3D scene setup */
.scene {
  width: 200px;
  height: 200px;
  perspective: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Cube setup */
.cube {
  top:10px;
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transition: transform 0.5s ease-in-out;
}

/* Cube faces */
.cube-face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.7;
}

div.inclinometer-type{
  position: absolute;
  color:#ccc;
  font-style: italic;
  bottom:0px;
  right:230px;
}

/* Face positions */
.cube-face.front {
  transform: translateZ(50px);
  background-color:var(--roll-color);
}
.cube-face.back {
  transform: rotateY(180deg) translateZ(50px);
  background-color:var(--roll-color);
}
.cube-face.left {
  transform: rotateY(-90deg) translateZ(50px);
  background-color:var(--pitch-color);
}
.cube-face.right {
  transform: rotateY(90deg) translateZ(50px);
  background-color:var(--pitch-color);
}
.cube-face.top {
  transform: rotateX(90deg) translateZ(50px);
  background-color:var(--yaw-color);
}
.cube-face.bottom {
  transform: rotateX(-90deg) translateZ(50px);
  background-color:var(--yaw-color);
}
</style>