//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTRoot  extends LCT{
  constructor(data,store) {
    super(data,store);
  }
  on_after_activate() {
    if (this.site_id !== undefined) {
      this.store.dispatch('db_global_set_active',{document_name:'site',id:this.site_id})
    }
    if (this.partner_id !== undefined) {
      this.store.dispatch('db_global_set_active',{document_name:'partner',id:this.partner_id})
    }
  }
  
}

//---------------------------------------------------------------------

@VueStore

class LCTRootDefinition extends LCTDefinition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name);
  }
}


//---------------------------------------------------------------------

export const root = {
  mixins:[lct]
}


//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTRootDefinition(definition,LCTRoot,context,name)
}

//---------------------------------------------------------------------

const definition = {
  definition:'root',
  user_collection:'users',
  views:{
    
  },
  documents:{
    
  },
  collections:{
    loadcells:{collection:'/loadcells',live:true,load:'load',where:[['deleted','==',false]]},
    inclinometers:{collection:'/inclinometers',live:true,load:'load',where:[['deleted','==',false]]},
    thermometers:{collection:'/thermometers',live:true,load:'load',where:[['deleted','==',false]]},
    users:{collection:'/users',live:true,load:'load',where:[['deleted','==',false]]},
    results:{collection:'/results',live:true,load:'load',where:[['deleted','==',false]]},
    tests:{collection:'/tests',live:true,load:'load',where:[['deleted','==',false]]}
    // logs:{collection:'/logs/20240919/logs',live:true,load:'load',where:[['deleted','==',false]]},
    
  }
}

//---------------------------------------------------------------------





































// export const client = {
//   components: {
    
//   },
//   mixins:[definition],
//   props:{
//     client:{type:Object,required:true},
//   },
//   data() {
//     return {
//       collections:{
//         travel_policies:{
//           live_bind:true,
//           conditions:{
//             not_deleted:{field:'deleted',operator:'==',value:false
//           }
//         }
//       },
//       spending_caps:{
//         live_bind:false,
//         conditions:
//         {
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       },
//       countries:{
//         live_bind:false,
//         conditions:
//         {
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       },
//       permissions:{
//         live_bind:true,
//         conditions:{not_deleted:{field:'deleted',operator:'==',value:false}}
//       },
//       custom_fields:{
//         live_bind:true,
//         conditions:{not_deleted:{field:'deleted',operator:'==',value:false}}
//       },
//       offices:{live_bind:false,
//         conditions:
//         {
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       },
//       layers:{live_bind:false,
//         conditions:
//         {
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       },
//       trips:{live_bind:true,
//         conditions:
//         {
//           // has_name:{field:'name',operator:'>',value:''},
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       }
//     }
//   }
// },
//   mounted() {
//   },
//   computed:{
//     client_name:{
//       get() {
//         return this.client.name;
//       },
//       set(value) {
//         this.client.name = value;
//         this.update_db('name',value)
//       }
//     }
//   }

// }


// import Vue from 'vue';

// import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

// import {definition as default_definition,Definition} from './definition.js';

// export class Partner extends Definition {
//   constructor(data,state) {
//     super(data,state,definition)
//   }
//   get collection() {
//     return `/partners`
//   }
// }

// export const definition =
// {
//   collection() {
//     return 'partners'
//   },
//   document_name:'partner',
//   class:Partner,
// };

// export const partner = {
//   mixins:[default_definition],
//   props:{
//     partner:{type:Object,required:true},
//   },
//   data() {
//     return {
//       definition:definition,
//       collections:{
//         zones:{
//           live_bind:true,
//           conditions:{
//             not_deleted:{field:'deleted',operator:'==',value:false
//           }
//         }
//       },
//       employees:{
//         live_bind:true,
//         conditions:
//         {
//           not_deleted:{field:'deleted',operator:'==',value:false}
//         }
//       }
//     }
//   }
// },
// mounted() {
// },
//   computed:{
//     can_i_call_this_property() {
//       return Math.random(100)
//     },
//     client_name:{
//       get() {
//         return this.client.name;
//       },
//       set(value) {
//         this.client.name = value;
//         this.update_db('name',value)
//       }
//     }
//   }

// }
