//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTTest  extends LCT{
  constructor(data,store) {
    super(data,store);
  }
}

//---------------------------------------------------------------------

@VueStore
class LCTTestDefinition extends LCTDefinition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name);
  }
}

//---------------------------------------------------------------------

export const test = {
  mixins:[lct]
}

//---------------------------------------------------------------------


const definition = {
  description_field_name:'name',
  definition:'test',
  views:{
  },
  collections:{
  },
  
  fields:{
    id:{
      field:'id',
      label:'id',
      datatype:'id',
      search:true,
      read:true,
      description_field:'name',
      views:{item:true,list:true}
    },
    name:{
      field:'name',
      label:'Name',
      datatype:'text',
      search:true,
      description:true,
      read:true,
      views:{item:true,list:true}
    },
    sensors:{
      field:'sensors',
      type:'json',
      read:true,
      write:false,
      search:false,
      views:{}
    },
    description:{
      field:'description',
      label:'Description',
      datatype:'text',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    startDate:{
      field:'startDate',
      label:'Start Date',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },

    endDate:{
      field:'endDate',
      label:'Start Date',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    }

    
  }
}

//---------------------------------------------------------------------


export function Definition(context,name) { 
  return new LCTTestDefinition(definition,LCTTest,context,name)
}

//---------------------------------------------------------------------


