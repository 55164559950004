//---------------------------------------------------------------------

import pluralize from 'pluralize';

//---------------------------------------------------------------------

import { getDefinition } from './db_definitions_store.js';

//---------------------------------------------------------------------
export function db_string_to_id(str) {
  let res = str;
  res = res.toLowerCase();
  res = res.replace(/[àáâäæãåā]/gi,"a");
  res = res.replace(/[èéêëēėę]/gi,"e");
  res = res.replace(/[iîiïiíiīiįiì]/gi,"i")
  res = res.replace(/[ôöòóœøōõ]/gi,"o");
  res = res.replace(/[ûüùúū]/gi,'u');
  res = res.replace(/[ÿ]/,'y');
  res = res.replace(/[ßśš]/gi,'s');
  res = res.replace(/[ł]/gi,"l");
  res = res.replace(/[žźż]/gi,"z");
  res = res.replace(/[çćč]/gi,"c");
  res = res.replace(/[ñń]/gi,'n');
  res = res.split("(").join(' ');
  res = res.split(")").join(' ');
  res = res.split("[").join(' ');
  res = res.split("]").join(' ');
  res = res.split("{").join(' ');
  res = res.split("}").join(' ');
  res = res.split("$").join(' ');
  res = res.split("|").join(' ');
  res = res.split("±").join(' ');
  res = res.split("§").join(' ');
  res = res.split(/[-&,\\ \./_@'"+*=#!%^`<>?]/).filter(function(t) {return t.length > 0}).join('_');
  return res;
}


export function db_tokens(text,splitter) {
  return text.split(splitter).filter(function(t) {return t !== ''} );
}

//---------------------------------------------------------------------

export function path_tokens(path) {
  let tokens = path.split('/').filter(function(T) { return T.length > 0});
  let res = []
  for (let t = 0 ; t < tokens.length ; t = t + 2 ) {
    let collection = tokens[t];
    let document = tokens[t + 1];
    if (document !== undefined) {
      res.push({collection,document});
    } 
  }
  return res;
}

//---------------------------------------------------------------------

export function db_collection_and_document_name(collection_path,context) {
  let res = {};
  let path_tokens = db_tokens(collection_path,'/');
  res.collection_name = path_tokens[path_tokens.length - 1];
  res.document_name = pluralize.singular(res.collection_name);
  res.definition = getDefinition(res.document_name);
  return res;
}

//---------------------------------------------------------------------

export function objects_from_path(path,root_object) {
  let res = {};
  let collection_path_tokens = path_tokens(path);
  let obj = root_object;
  for (let p = 0 ; p < collection_path_tokens.length ; p ++ ) {
    let path_token = collection_path_tokens[p];
    let collection  = path_token.collection;
    let document_name = pluralize.singular(collection);
    let document_id = path_token.document;    
    if (obj[collection] !== undefined && obj[collection][document_id] !== undefined) {
      obj = obj[collection][document_id];
      res[document_name] = obj;
    }
    else 
    {
      break
    }
    
  }
return res;    
}

//---------------------------------------------------------------------

export function db_path(path_elements) {
  let path = '';
  if (path_elements instanceof Array) {
    path = '/'+path_elements.join('/')
  } else
  if (typeof path_elements === 'string') {
    path = '/'+db_tokens(path_elements,'/').join('/')
  }
  return path;
}

//---------------------------------------------------------------------

export function db_collection_and_document(path) {
  let res = {};
  let tokens = db_tokens(path,'/');
  let document_id_array = tokens.splice(tokens.length-1,1);
  res.collection = db_path(tokens);
  res.document_id = document_id_array[0];
  return res;
}

//------------------------------------------------------------------------------

