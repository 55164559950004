<template>
    <div class="lct-test-container">
      <div class="test-name">{{test.name}}</div>
      <div class="test-date">Date: {{startDateStr}}</div>
      <div class="logCount">Logs: {{logCount}}</div>
      <div class="test-graphs">
        <div class="test-graph">
         <LCTGraph  
           :testID="id"
           :logs="testLogs"
           loadCellType="LOADCELLS"
           temperatureSensor="temp-a-1"
           loadCellID="S-A"
           sCellID="S-B" />
        </div>       
      </div>
    </div>
</template>


<script>

import LCTGraph from './LCTGraph.vue';

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {
    LCTGraph
  },
  props: {  
     id:{
      type:String,
      required:true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['tests','results']),
    startDateStr() {
      let C1,C2,Y1,Y2,M1,M2,D1,D2,h1,h2,m1,m2,s1,s2;
      [C1,C2,Y1,Y2,M1,M2,D1,D2,h1,h2,m1,m2,s1,s2] = String(this.test.startDate).split('');
      let dateStr = `${C1}${C2}${Y1}${Y2}${M1}${M2}${D1}${D2}`;
      return dateStr; 
    },
    logCount() {
      return this.testLogs.length;
    },
    testLogs() {
      let logs = (this.results?.[this.startDateStr]?.logs === undefined) ? {} : this.results?.[this.startDateStr]?.logs;
      return Object.values(logs);
    },
    test() {
      return this.tests[this.id]
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

	div.container{

  }

</style>


