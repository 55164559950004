<template>
    <div class="lct-caliper-container" :class="caliperClass" :style="style">
      <div class="caliper-id">{{caliper.id}}</div>

      <div class="caliper-image" :class="caliperType">
        <div class="caliper-measurement">{{caliper.Measurement}}</div>
        <div class="caliper-mm">mm</div>
      </div>
      <!-- <div class="caliper-type">{{caliper.type}}</div> -->
      <div class="caliper-kg">{{caliper.kg || caliper.Measurement }}kg</div>
      <!-- <div class="caliper-online">{{caliper.Online}}</div> -->
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    caliper:{type:Object,required:true}
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    caliperType() {
      return (this.caliper.type || 'vernier');
    },
    caliperClass() {
      let classes = [];
      let onlineClass = (this.caliper.Online === 1 || this.caliper.Status === 1 ) ? 'online' : 'offline' ;
      classes.push(onlineClass);
      return classes.join(' ');
    },
    style() {
       return {'--bg-image':`url(/img/loadcells/${this.caliperType || this.caliper.id}.png)`}
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>

<style>

div.lct-caliper-container{
  position:relative;
   display:flex;
   min-height: 100px;   
   margin:5px;
   max-width:300px;
   min-width:300px;
   border: 2px solid var(--online-color);
    border-radius: 5px;
    padding:5px;

}

div.lct-caliper-container.online{
  --online-color:#178B4D;
}

div.lct-caliper-container.offline{
  --online-color:#C42511;
}


div.lct-caliper-container > div{
    flex:2;
    display:flex;
    align-items:center;
    justify-content: center;
    /* margin-left:5px; */
}

div.caliper-id{
  flex:3;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  background-color:var(--online-color);
  color:white;
  font-weight: bold;
  text-transform: uppercase;
}

div.caliper-kg{
  position: absolute;
  top:30px;
  right:0px;
  padding-right: 10px;

}

div.caliper-mv{
  position: absolute;
  bottom:10px;
  right:0px;
  padding-right: 10px;
}

div.caliper-image{
  margin-top:25px;
  flex:1;
  display:flex;
  background-size:cover;
  background-position: center center;
  max-width:200px;
  min-height:95px;
  background-repeat:no-repeat;
  background-image:var(--bg-image);
  position: relative;
}

div.caliper-measurement {
  /* border: 1px solid red; */
  font-family: 'Open24Display', sans-serif;
  font-size: 40px;
  position: absolute;
  top:21px;
  right:85px;
}

div.caliper-mm{
  position:absolute;
  top:25px;
  right:63px;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
}

</style>


