<template>
    <div class="lct-loadcell-container" :class="loadcellClass" :style="style">
            <div class="loadcell-id">{{loadcell.id}}</div>

      <div class="loadcell-image" :class="loadcell.type"></div>
      <!-- <div class="loadcell-type">{{loadcell.type}}</div> -->
      <div class="loadcell-kg">{{loadcell.kg || loadcell.CalculatedLoad || loadcell.RawValue}}kg</div>
      <div class="loadcell-mv">{{loadcell.mV}}mV</div>
      <!-- <div class="loadcell-online">{{loadcell.Online}}</div> -->
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    loadcell:{type:Object,required:true}
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    loadcellClass() {
      let classes = [];
      let onlineClass = (this.loadcell.Online === 1 || this.loadcell.Status === 1 ) ? 'online' : 'offline' ;
      classes.push(onlineClass);
      return classes.join(' ');
    },
    style() {
       return {'--bg-image':`url(/img/loadcells/${this.loadcell.type || this.loadcell.id}.png)`}
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>

<style>

div.lct-loadcell-container{
  position:relative;
   display:flex;
   min-height: 100px;   
   margin:5px;
   max-width:200px;
   min-width:200px;
   border: 2px solid var(--online-color);
    border-radius: 5px;
    padding:5px;

}

div.lct-loadcell-container.online{
  --online-color:#178B4D;
}

div.lct-loadcell-container.offline{
  --online-color:#C42511;
}


div.lct-loadcell-container > div{
    flex:2;
    display:flex;
    align-items:center;
    justify-content: center;
    /* margin-left:5px; */
}

div.loadcell-id{
  flex:3;
  min-width: 150px;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  background-color:var(--online-color);
  color:white;
  font-weight: bold;
}

div.loadcell-kg{
  position: absolute;
  top:30px;
  right: 0px;
  padding-right: 10px;

}

div.loadcell-mv{
  position: absolute;
  bottom:10px;
  right:0px;
  padding-right: 10px;
}

div.loadcell-image{
  margin-top:20px;
  border: 3px solid var(--online-color);
  flex:1;
  display:flex;
  background-size:contain;
  background-position: center center;
  max-width:80px;
  min-height:80px;
  background-repeat:no-repeat;
  background-image:var(--bg-image);
  border-radius: 100px;
}

</style>


