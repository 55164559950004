//-----------------------------------------------------------------------------------------------

import {db_path,db_collection_and_document,db_collection_and_document_name,db_tokens} from './db_utils.js';

//-----------------------------------------------------------------------------------------------

import {DBObject,db_proxy_handler} from './db_object.js';
import { getDefinition } from './db_definitions_store.js';

//-----------------------------------------------------------------------------------------------

export function db_load_active_collections(context,record) {
  return  db_load_document_collections(context,record,'active')
}

//-----------------------------------------------------------------------------------------------

export function db_recurse_to_object(store,path,parent_path,is_collection) {
  return do_db_recurse_to_object(store.state,path,parent_path,is_collection,store)
}


export function do_db_recurse_to_object(object,path,parent_path,is_collection,store) {
  is_collection = (is_collection !== false);
  let fields = db_tokens(path,'/');
  if (fields.length === 0) {
    return object
  } else {
      let f = fields[0];
      if (is_collection !== false) { 
      }
      else 
      {
      }
      if (object[f] === undefined) {
        if (!is_collection) {
          let path_tokens = [...fields];
          // let doc_id = path_tokens.pop();
          // let collection_path = `${parent_path}/${f}`;
          object[f] = new DBObject({collection_path:parent_path,id:f},store);
        }
        else 
        {
          object[f] = {};
        }
        let remainingFields = fields.splice(1);
        return do_db_recurse_to_object(object[f], db_path(remainingFields),`${parent_path ?  parent_path : ''}/${f}`,!is_collection,store);
      }
      else {
          let remainingFields = fields.splice(1);
          return do_db_recurse_to_object(object[f], db_path(remainingFields),`${parent_path ? parent_path : ''}/${f}`,!is_collection,store)
      }
  }
}

//-----------------------------------------------------------------------------------------------

export function db_assign_document_to_state(context,data,collection_path,id,options) {
  let document_data = map_object(data,id,options);
  let path = `${collection_path}/${id}`;
  let fixed_path = db_path(path);
  let path_obj = db_collection_and_document(fixed_path);
  let object = db_recurse_to_object(context,path_obj.collection);
  let key = path_obj.document_id;
  if (object !== undefined && object[key] !== undefined && object[key].__fs !== undefined && object[key].__fs.version === options.version) {
    return object[key]
  }
  else 
  {
    options.collection_path = path_obj.collection;
    if (document_data.selected === undefined) {
      document_data.selected = true;
    }
    let res = db_assign_fields_to_object(context,object,key,document_data,options);
    return res;
  }
}

//-----------------------------------------------------------------------------------------------

export function db_update_state(context,collection_path,id,record,options) {
  return db_assign_document_to_state(context,record,collection_path,id,options);
}
//   let object = db_recurse_to_object(context.state,collection_path);
//   db_assign_fields_to_object(context,)
//   if (object[id] === undefined) {
//     object[id] = {}
//   }
//   for (var af in record) {
//     if (record.hasOwnProperty(af)) {
//       let val = record[af];
//       object[id][af] = val
//     }
//   }
// }

//-----------------------------------------------------------------------------------------------

export function db_delete_state(context,collection,id) {
  let object = db_recurse_to_object(context,collection);
  delete object[id];
}

//-----------------------------------------------------------------------------------------------

export function db_clone(obj) {
  let res = {};
  for (var f in obj) {
    if (obj.hasOwnProperty(f)) {
      if (obj[f] !== undefined) {
        if (f !== '.key' && f !== '__fs' && f !== 'id' && f !== '_change_from_db' && f !== '__db_path' && f !== '__st_path') {
          let fld = obj[f];
          if ((typeof fld === "object") && (fld instanceof Date === false) ) {
            if (!(fld instanceof Array)) {
              res[f] = db_clone(fld)
            }
            else
            if (fld instanceof Array ) {
              let arr = [];
              for (var e = 0 ; e < fld.length ; e ++ ) {
                let elm = fld[e];
                if (elm instanceof Object && !(elm instanceof Array) ) {
                  elm = db_clone(elm)
                }
                arr.push(elm)
              }
              res[f] = arr;
            }
          }
          else
          {
            res[f] = obj[f]
          }
        }
      }
    }
  }
  return res;
}

//-----------------------------------------------------------------------------------------------
// INTERNAL FUNCTIONS
//-----------------------------------------------------------------------------------------------

function db_assign_fields_to_object(context,object,key,data,options) {
  let state = context.state;
  data.id = key;
  if (data.selected === undefined) {
    data.selected = true;
  }
  let result_object;
  data.id = key;
  data.__fs = {};
  data.__fs.loaded = new Date().getTime();
  data.__fs.ref = options.ref;
  data.__fs.version = options.version;
  let change = options.change;
  if (change.type === "added") {
    if (object[key] === undefined) {
      if (options.hook !== undefined) {
        options.hook(data)
      }
      let DefaultClass = DBObject;
      data.collection_path = options.collection_path;
      let collection_document = db_collection_and_document_name(options.collection_path,context);
      let objClass = collection_document.definition?.class ?? DefaultClass;
      let objInstance = new objClass(data,context);
      let use_data = new Proxy( objInstance,db_proxy_handler);
      object[key] = use_data;
      result_object = use_data;
      if (options.auto_load !== false) {
        db_get_on_load_collections(context,use_data)
      }
    }
    else {
      for (let af in data) {
        if (data.hasOwnProperty(af)) {
          object[key][af] = data[af]
        }
      }
    }
    let result_object_path = options.collection_path+'/'+key;
    result_object = db_recurse_to_object(context,result_object_path);
    
  } else
  if (change.type === "modified" && data.deleted === false) {
    if (options.hook !== undefined) {
      options.hook(data)
    }
    if (object[key] === undefined) {
      object[key] = data;
    }
    else {
      let obj_to_update = object[key];
      object[key]._change_from_db = true;
      for (var mf in data) {
        if (data.hasOwnProperty(mf)) {
          object[key][mf] = data[mf] 
        }
        object[key]._change_from_db = false;
      }
    }
    
    let result_object_path = options.collection_path+'/'+key;
    result_object = db_recurse_to_object(context,result_object_path);
    
  } else
  if (change.type === "removed" || data.deleted === true) {
    delete  object[key];
  }
  if (options.callback !== undefined) {
    options.callback(data,options);
  }
  return result_object;
  
}
  
//-----------------------------------------------------------------------------------------------

function map_object(doc,id,options) {
  return doc;
}
  
//-----------------------------------------------------------------------------------------------

function db_get_on_load_collections(context,record) {
  return db_load_document_collections(context,record,'load');
}

//-----------------------------------------------------------------------------------------------
    
function on_sub_document_loaded_success(sub_document,options) {
  let field = options.field;
  let original_record = options.record;
  original_record[field] = sub_document;
}

//-----------------------------------------------------------------------------------------------
  
export function db_load_document_collections(context,record,load_event) {
  let document_name = record.document_name;
  let documents   = getDefinition(document_name)?.documents ?? {};
  let collections = getDefinition(document_name)?.collections ?? {};
  do_db_load_document_collections(context,record,load_event,documents,collections)
}

export function do_db_load_document_collections(context,record,load_event,documents,collections) {
  for (let d in documents) {
    let doc = documents[d];
    let doc_id = _.get(record,doc.id_field);
    if (doc_id === undefined) {
    }
    let doc_path ;
    if (typeof doc.collection === "undefined") {
      doc_path = `${record.collection_path}/${record.id}/${c}`;
    } else  
    if (typeof doc.collection === "string")
    {
      doc_path = doc.collection
    } else 
    if (typeof doc.collection === "function") {
      doc_path = doc.collection(record,doc)
    }
    if (doc.load === load_event) {
      let opts = {};
      // if (doc.local_field === true) {
        opts.on_success = on_sub_document_loaded_success;
        opts.record = record;
        opts.field = d;
        // }

        context.dispatch('db_get_document',{collection_path:doc_path,id:doc_id,options:opts})
      }
    }
    
    for (let c in collections) {
      let collection = collections[c];
      if (collection.load === load_event) {
        let collection_path ;
        if (typeof collection.collection === "undefined") {
          collection_path = `${record.collection_path}/${record.id}/${c}`;
        } else  
        if (typeof collection.collection === "string")
        {
          collection_path = collection.collection
        } else 
        if (typeof collection.collection === "function") {
          collection_path = collection.collection(record)
        }
        let options = {};
        options.live = (collection.live !== false);
        let current_where = (collection.where === undefined) ? [['deleted','==',false]] : collection.where;
        let use_where = [];
        for (let w = 0 ; w < current_where.length ; w ++ ) {
          
          let where = map_where(current_where[w],record,context);
          // [null,null,null]; 
          // where[0] = current_where[w][0];
          // where[1] = current_where[w][1];
          // where[2] = map_where(record,current_where[w][2]);
          use_where.push(where)
        }
        options.where = use_where;
        options.record = record;
        context.dispatch('db_get_collection',{collection_path:collection_path,options});
      }
    }
  }
  
function map_where(where,record,context) {
  
    if (typeof where === 'function') {
      return where(record,context)
    } else 
    if (typeof where === 'object' && where[2].field !== undefined) {
      return [where[0],where[1],record[ where[2].field]]
    }
    else 
    {
      return [where[0],where[1],where[2]];
    }
  }

//-----------------------------------------------------------------------------------------------

