//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTThermometer  extends LCT{
  constructor(data,store) {
    super(data,store);
  }
}

//---------------------------------------------------------------------

@VueStore
class LCTThermometerDefinition extends LCTDefinition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name);
  }
}

//---------------------------------------------------------------------

export const thermometer = {
  mixins:[lct]
}

//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTThermometerDefinition(definition,LCTThermometer,context,name)
}

//---------------------------------------------------------------------

const definition = {
  definition:'thermometer',
  views:{
  },
  collections:{
  },
  
  fields:{
    id:{
      field:'id',
      label:'Name',
      datatype:'id',
      search:true,
      read:true,

      description_field:'name',
      views:{item:true,list:true}
    },
    type:{
      field:'type',
      label:'Type',
      datatype:'text',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    description:{
      field:'description',
      label:'Description',
      datatype:'text',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    zero:{
      field:'zero',
      label:'Zero',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    }
    
  }
}

//---------------------------------------------------------------------







