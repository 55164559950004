//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTInclinometer  extends LCT{
  constructor(data,store) {
    super(data,store);
  }
}

//---------------------------------------------------------------------

@VueStore
class LCTInclinometerDefinition extends LCTDefinition {
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name);
  }
}

//---------------------------------------------------------------------

export const inclinometer = {
  mixins:[lct]
}

//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTInclinometerDefinition(definition,LCTInclinometer,context,name)
}

//---------------------------------------------------------------------

const definition = {
  definition:'inclinometer',
  views:{
  },
  collections:{
  },
  
  fields:{
    id:{
      field:'id',
      label:'Name',
      datatype:'id',
      search:true,
      read:true,

      description_field:'name',
      views:{item:true,list:true}
    },
    type:{
      field:'type',
      label:'Type',
      datatype:'text',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    zeroRoll:{
      field:'zeroRoll',
      label:'Zero Roll',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    zeroPitch:{
      field:'zeroPitch',
      label:'Zero Pitch',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    zeroYaw:{
      field:'zeroYaw',
      label:'Zero Yaw',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },

    zeroX:{
      field:'zeroX',
      label:'Zero X',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },

    zeroY:{
      field:'zeroY',
      label:'Zero Y',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },
    
    zeroZ:{
      field:'zeroZ',
      label:'Zero Z',
      datatype:'number',
      read:true,
      write:true,
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    },

    
  }
}

//---------------------------------------------------------------------







