import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LCTLatest from '@/views/LCTLatest.vue';
import LCTTests from '@/views/LCTTests.vue';
import LCTTest from '@/views/LCTTest.vue';
import LCTCreateTest from '@/views/LCTCreateTest.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/latest',
    name: 'home',
    component: LCTLatest
  },
  {
    path: '/',
    name: 'latest',
    component: LCTLatest
  },

  {
    path: '/test/:id',
    name: 'test',
    component: LCTTest,
    props:true,

  },
  {
    path: '/tests',
    name: 'tests',
    component: LCTTests,
  },
  {
    path:'/createTest',
    name:'createTest',
    component:LCTCreateTest
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
