//---------------------------------------------------------------------

export {DBObject}   from './js';
export {Definition} from './js';
export {definition} from './js';
export {db_interface_firestore} from './js';
export {db_vuex_state} from './js';
export {db_vuex_actions} from './js';
import {db_vuex_store_getters} from './js';
export {getDefinition} from './js';
import {db_events} from './js'

const onEvent = db_events.onEvent;
const offEvent = db_events.offEvent;
const sendEvent = db_events.sendEvent;

export {onEvent,offEvent,sendEvent};

import {root} from './js';
export const db_vuex_getters  = {root,...db_vuex_store_getters};

//---------------------------------------------------------------------
