//---------------------------------------------------------------------

import {addDefinition} from './db_definitions_store.js';
import pluralize from 'pluralize';

//---------------------------------------------------------------------

export function db_global_set_active(context,record) {
  context.state.active[record.document_name] = record.id;
  context.dispatch('db_load_active_collections',record);
}

//---------------------------------------------------------------------

export function db_load_definitions(context,definitions) {
  for (let d in definitions) {
    let definition = definitions[d];
    let definitionClass = definition(context,d);
    addDefinition(d,definitionClass);
  }
}

//---------------------------------------------------------------------

export const db_vuex_state = {
  active:{},
  definitions:{}
}

//---------------------------------------------------------------------

export const db_vuex_actions = {
  db_load_definitions,
  db_global_set_active
}

function db_active_user(state) {
  let document_name = pluralize.singular(state.user_collection);
  return state[state.user_collection][state.active[document_name]];
} 

function db_active_user_id(state) {
  let document_name = pluralize.singular(state.user_collection);
  return state[state.user_collection][state.active[document_name]].uid;
}

function db_active_user_email(state) {
  let document_name = pluralize.singular(state.user_collection);
  return state[state.user_collection][state.active[document_name]].email;
}

export const db_vuex_getters = {
  active_user:db_active_user,
  db_active_user:db_active_user,
  db_active_user_id:db_active_user_id,
  db_active_user_email:db_active_user_email
}

//---------------------------------------------------------------------
