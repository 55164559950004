//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTUser extends LCT{
  constructor(data,store) {
    super(data,store)
  }
}

//---------------------------------------------------------------------
@VueStore

class LCTUserDefinition extends LCTDefinition{
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name)
  }
}

//---------------------------------------------------------------------

export const user = {
  mixins:[lct],
  props:{
    user:{type:Object,required:true}
  }
}

//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTUserDefinition(definition,LCTUser,context,name);
}
//---------------------------------------------------------------------

const definition = {
  definition:'user',
 
  views:{
    detail:{type:'item',record:'vehicle'},
  },
  fields:{
    id:{
      field:'id',
      label:'ID',
      datatype:'id',

      search:true,
      description_field:'name',
      views:{item:true,list:true}
    },
    name:{
      field:'name',
      label:'Name',
      write:true,
      datatype:'text',
      search:true,
      views:{item:true,edit:true,list:false,create:true}
    }

  },
  documents:{
  },
  collections:{

}
}

//---------------------------------------------------------------------

