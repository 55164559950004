<template>
  <div>
    <canvas id="logChart"></canvas>
  </div>
</template>

<script>
// Import necessary components from Chart.js
import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip, Legend } from 'chart.js';
// Import the date adapter (for handling time scales)
import 'chartjs-adapter-date-fns';

export default {
  props:{
    logs:{type:Array,required:true},
    setup:{type:Object,required:true}
  },
  name: 'LogChart',
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // Register the necessary components for Chart.js
    Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip, Legend);

    this.renderChart();
  },
  methods: {
    renderChart() {
      const ctx = document.getElementById('logChart').getContext('2d');

      // Example log data (timestamps + values for temperature and weight)
      let logs = this.logs;
      let x_scale = {
              type: 'time', // Time-based x-axis
              time: {
                unit: 'hour', // Adjust the time unit based on your log frequency (auto-scales)
              },
              title: {
                display: true,
                text: 'Time',
              },
            };
      let options = {};
      options.scales = {};
      options.scales.x = x_scale;
      // Extract the times and values for the datasets
      const xAxisLabels = logs.map(log => log[this.setup.x_axis.field]); // Time in ISO format or as Date objects
      // const temperatureData = logs.map(log => log.temperature);
      // const weightData = logs.map(log => log.weight);
      let datasets = [];
      for (let y = 0 ; y < this.setup.y_axis.length ; y ++ ) {
        let ySetup = this.setup.y_axis[y];
        let yDataset = {};
        let yOption = {};
        yOption.type = ySetup.scaleType;
        yOption.position = ySetup.position;
        yOption.title = {};
        yOption.title.display = (ySetup.displayTitle || true);
        yOption.title.text = ySetup.axis + ' ('+ ySetup.unit +')';
        options.scales[ySetup.axis] = yOption;
        yDataset.label = ySetup.title;
        yDataset.borderColor = ySetup.color;
        yDataset.yAxisID = ySetup.axis;
        yDataset.fill = (ySetup.fill === true);
        yDataset.tension = ySetup.tension || 0.4;
        let data = logs.map(log => log[ySetup.id]);
        yDataset.data = data;
        datasets.push(yDataset);
      }
      let scales =   {};
      scales.x = x_scale;
      // Create the chart

      let chartConfig = {
        type: 'line',
        data: {
          labels: xAxisLabels, // Use times (timestamps) as labels
          datasets:datasets,
        },
        options:options
      };
      console.log('ChartConfig: ',chartConfig);
      this.chart = new Chart(ctx, chartConfig);
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
}
</style>
