//@ts-nocheck
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {store} from './store'
import {definitions} from '@/store/definitions.js';

const app = createApp(App);
app.use(store);
app.use(router)
app.mount('#app').$nextTick(() => {
  
});

router.isReady().then(() =>  {
  store.dispatch('db_load_definitions',definitions);
  let user = {name:"Rhyno",id:"1",uid:"1",collection_path:"/users"};
  store.dispatch('user_authenticated',user);
});




