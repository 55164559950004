
/**
 * Collection of definitions
 *
 *
 * Note:
 * If definitions are to be reactive, then the Map could be replaced with `reactive`
 * e.g.
 *      const definitions = reactive({});
 *
 * In that case we would need to export definitions with toRefs through a hook
 * e.g.
 *      const useDefinitions = () => toRefs(definitions);
 *
 * ...and would be consumed in setup() via
 *      setup() {
 *        const { trip } = useDefinitions();
 *      }
 */

const definitions = new Map();


if (process.env.NODE_ENV === 'development') {
  window.definitions = definitions;
}

/**
 * Add a single definition to the collection.
 * Optionally, you could add a persistence layer (e.g. caching) for definitions.
 * If so, they could preload faster by showing the old result
 * before the new one comes back from the server
 * @param {string} name The name of the definition (e.g. trip)
 * @param {Definition} definition The definition descriptor object
 * @return {Definition[]} Returns the collection
 */
export function addDefinition(name, definition) {
  definitions.set(name, definition);
  return definitions;
}

/**
 * Add multiple new definitions
 * @param {Array<string, Definition>} newDefinitions
 * @return {Definition[]} Returns the collection
 */


export function addDefinitions(newDefinitions) {
  newDefinitions.forEach(([name, definition]) => addDefinition(name, definition));
  return definitions;
}

/**
 * Get a definition by name
 * @param {string} name Definition name
 * @return {Definition} Returns the definition
 */
export function getDefinition(name) {
  return definitions.get(name);
}

/**
 * As the default export, we allow imports to use a custom reference name more easily
 */


export default definitions;

window.allDefinitions = definitions;

