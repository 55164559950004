//---------------------------------------------------------------------


import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

function rollPitchYawToVector(roll, pitch, yaw) {
  // Convert angles from degrees to radians
  const rollRad = roll * (Math.PI / 180);
  const pitchRad = pitch * (Math.PI / 180);
  const yawRad = yaw * (Math.PI / 180);

  // Calculate the components of the vector
  const x = Math.cos(pitchRad) * Math.cos(yawRad);
  const y = Math.cos(pitchRad) * Math.sin(yawRad);
  const z = Math.sin(pitchRad);

  return { x: x, y: y, z: z };
}


function calculateAngleBetweenVectors(V_zero, V_current) {
  // Dot product of the two vectors
  const dotProduct = (V_zero.x * V_current.x) + (V_zero.y * V_current.y) + (V_zero.z * V_current.z);
  
  // Magnitude of the vectors (for unit vectors, this should be 1, but calculating in general case)
  const magnitudeZero = Math.sqrt((V_zero.x ** 2) + (V_zero.y ** 2) + (V_zero.z ** 2));
  const magnitudeCurrent = Math.sqrt((V_current.x ** 2) + (V_current.y ** 2) + (V_current.z ** 2));
  
  // If either vector has zero magnitude, return 0
  if (magnitudeZero === 0 || magnitudeCurrent === 0) {
    return 0;
  }

  // Calculate the angle in radians using the dot product formula
  const angleRadians = Math.acos(dotProduct / (magnitudeZero * magnitudeCurrent));

  // Convert the angle from radians to degrees
  const angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}

function  magnitude(x,y,z) {
  return Math.sqrt(x ** 2 + y ** 2 + z ** 2);
}



function calculateAngleBetweenPositions(roll_ref, pitch_ref, yaw_ref, currentRoll, currentPitch, currentYaw) {
  // Create vectors for the zero position and current position
  const V_zero = { x: roll_ref, y: pitch_ref, z: yaw_ref };
  const V_current = { x: currentRoll, y: currentPitch, z: currentYaw };

  if (V_zero.x === V_current.x && V_zero.y === V_current.y && V_zero.z === V_current.z ) {
    return 0;
  }
  
  // Dot product of the two vectors
  const dotProduct = (V_zero.x * V_current.x) + (V_zero.y * V_current.y) + (V_zero.z * V_current.z);
  
  // Magnitude of the vectors
  const magnitudeZero = Math.sqrt((V_zero.x ** 2) + (V_zero.y ** 2) + (V_zero.z ** 2));
  const magnitudeCurrent = Math.sqrt((V_current.x ** 2) + (V_current.y ** 2) + (V_current.z ** 2));
  
  // Calculate the angle in radians using the dot product formula
  const angleRadians = Math.acos(dotProduct / (magnitudeZero * magnitudeCurrent));

  // Convert the angle from radians to degrees
  const angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}

window.calculateAngleBetweenPositions = calculateAngleBetweenPositions;

@VueStore

class LCTLog extends LCT {
  constructor(data,store) {
    super(data,store)
  }



  get angles() {
    let res = {};
    let inclinometers = this.__state.inclinometers;
    for (let i in this.data.INCLINOMETERS ) {
      let inclinometer = this.data.INCLINOMETERS[i];
      let zero = {zeroRoll:0,zeroPitch:0,zeroYaw:0};
      if (inclinometers[i] !== undefined) {
        zero.zeroRoll = inclinometers[i].zeroRoll;
        zero.zeroPitch = inclinometers[i].zeroPitch;
        zero.zeroYaw = inclinometers[i].zeroYaw;
      }
      let roll  = inclinometer.roll;
      let pitch = inclinometer.pitch;
      let yaw   = inclinometer.yaw;
      res[i] = {};
      res[i].r = roll  - zero.zeroRoll;
      res[i].p = pitch - zero.zeroPitch;
      res[i].y = yaw   - zero.zeroYaw;
      res[i].x = roll  ;
      res[i].y = pitch ;
      res[i].z = yaw   ;
      res[i].magnitude = magnitude(roll,pitch,yaw);
      res[i].vector      = rollPitchYawToVector(res[i].r,res[i].p,res[i].y);
      res[i].vectorAngle = 90 - calculateAngleBetweenVectors(res[i].vector,{x: 0, y: 0, z: 1});
      // res[i].angle  = calculateAngleBetweenPositions(zero.zeroRoll, zero.zeroPitch, zero.zeroYaw, inclinometer.roll, inclinometer.pitch, inclinometer.yaw);
    }
    return res;
  }

}

//---------------------------------------------------------------------

@VueStore
class LCTLogDefinition extends LCTDefinition{
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name)
  }
}

//---------------------------------------------------------------------

export const log = {
  mixins:[lct],
  props:{
    product:{type:Object,required:true}
  }
}

//---------------------------------------------------------------------

export function Definition(context,name) { 
  return new LCTLogDefinition(definition,LCTLog,context,name)
} 

//---------------------------------------------------------------------

const definition = { 
  definition:'product',
  views:{
    detail:{type:'item',record:'product'}
  },
  fields:{
    id:{
      field:'id',
      label:'Name',
      datatype:'id',
      description_field:'name',
      options:{},
      views:{item:false,list:true}
    },
    
    LOADCELLS:{
      field:'LOADCELLS',
      label:'Load Cells',
      datatype:'object',
      search:true,
      views:{item:true,edit:false,list:true,create:true}
    },

    TEMP_SENSORS:{
      field:'TEMP_SENSORS',
      label:'Temperature Sensors',
      write:true,
      datatype:'text',
      options:{},
      views:{item:true,edit:true,list:false,create:true}
    },


  }
  

 }

//---------------------------------------------------------------------


