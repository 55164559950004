<template>
    <div  v-if="has_latest_log" class="lct-latest-container">
      <div class="last-updated">
        Last updated: {{lastUpdated}}
      </div>
      <div class="load-cells">
        <div class="load-cells-header">
          LOAD CELLS
        </div>
        <div class="load-cell-content">
        <template v-for="loadcell in loadCells"  :key="loadcell.id">
         <LCTLoadCell  :loadcell="loadcell"   />
        </template>
        </div>
      </div>

     <div class="inclinometers">
        <div class="inclinometer-header">
          INCLINOMETERS
        </div>
        <div class="inclinometer-content">
        <template v-for="inclinometer in inclinometers" :key="inclinometer.id">
          <template v-if="inclinometer.accel_x !== undefined">
         <LCTInclinometer  :inclinometer="inclinometer" :angles="angles" />
          </template>
        </template>
        </div>
      </div>
      
      <div class="calipers">
        <div class="calipers-header">
          DISPLACEMENT SENSORS
        </div>
        <div class="calipers-content">
        <template v-for="caliper in calipers"  :key="caliper.id">
         <LCTCaliper  :caliper="caliper" />
        </template>
        </div>
      </div>
  

      <div class="temp-sensors">
        <div class="temp-sensor-header">
          TEMP SENSORS 
        </div>
        <div class="temp-sensor-content">
        <template v-for="sensor in temperatureSensors" :key="sensor.id" >
           <LCTTemperature  :sensor="sensor"  />
        </template>
        </div>
      </div>
    </div>
</template>


<script>

import LCTLoadCell from './LCTLoadCell.vue';
import LCTTemperature from './LCTTemperature.vue';
import LCTInclinometer from './LCTInclinometer.vue';
import LCTCaliper from './LCTCaliper.vue';

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {LCTTemperature,LCTLoadCell,LCTInclinometer,LCTCaliper},
  props: {  
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters(['latest_log']),
  	...mapState([]),
    angles() {
      return this.latest_log.angles;
    },
    has_latest_log() {
      return this.latest_log !== undefined;
    },
    lastUpdated() {
      let date =  new Date(this.latest_log.__fs.version / 1000000);
      return date.toLocaleString('en-ZA');
    },
    calipers() {
      return Object.values(this.latest_log.DISPLACEMENT_SENSORS).sort((a,b) => a.id > b.id ? 1 : -1);
    },
    loadCells() {
      return Object.values(this.latest_log.LOADCELLS).sort((a,b) => a.id > b.id ? 1 : -1);
    },
    temperatureSensors() {
      return Object.values(this.latest_log.TEMP_SENSORS).sort((a,b) => a.id > b.id ? 1 : -1);
    },
    inclinometers() {
      return Object.values(this.latest_log.INCLINOMETERS).sort((a,b) => a.id > b.id ? 1 : -1);
    }
    },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>
  div.load-cell-content,div.temp-sensor-content,div.inclinometer-content,div.calipers-content{
    display:flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* border: 1px solid red; */
  }

div.last-updated{
  color:#ccc;
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  padding-left:10px;
  position:absolute;
  top:10px;
}

div.load-cells-header,div.temp-sensor-header, div.inclinometer-header,div.calipers-header{
  color:#999;
  font-weight: bold;  
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  font-size: 12px;
  margin-top:30px;
  padding-left:10px;
}

</style>


