//------------------------------------------------------------------------------

import {db} from './db_firestore.js';
import { serverTimestamp,collection, query, where,doc,updateDoc,onSnapshot,setDoc } from 'firebase/firestore';

const _ = require("lodash");

//------------------------------------------------------------------------------

import {db_load_active_collections,db_assign_document_to_state,db_recurse_to_object,db_clone,db_update_state,db_delete_state} from './db_state.js';
import {db_tokens,db_collection_and_document_name,db_path} from './db_utils.js';

//------------------------------------------------------------------------------
/** 
* Retrieve or Bind to a Collection in Firebase. 
* 
* @typedef {Object} VuexStoreContext

* @param {VuexStoreContext} - Context of Vuex Firestore
* @param {Object}  PathAndOptions
* @param {string}  PathAndOptions.collection_path  - The collection path of the collection in firebase. 
* @param {object} PathAndOptions.options - Additional options 
* @param {boolean} PathAndOptions.options.live [live=true] - Whether or not the system should stay bound to the DB. 
* @param {function} PathAndOptions.options.on_receive_document - Callback function to be called with the document received by the datastore. callback(data,options)
* @param {Array[]} PathAndOptions.options.where - An array of where clauses used to query the data: (Default: [['deleted','==',false]])
*
* @return {Object} FirestoreRef - Firestore Collection Reference
* 
*/

export function db_get_document_ref(document_path,document_id) {
  const pathSegments = document_path.split('/').filter( s => s);
  const docRef = doc(db, ...pathSegments,document_id);
  return docRef;
}

export async function db_get_document_from_ref(docRef) {
  const docSnap = await getDoc(docRef);
  return docSnap;
}

export function db_get_collection_ref(collection_path) {
  const pathSegments = collection_path.split('/').filter( s => s);
  const collectionRef = collection(db,...pathSegments)  ;
  return collectionRef;
}

export function db_update_doc(docRef,updatedDoc) {
  return updateDoc(docRef,db_clone(updatedDoc));
}

export function db_create_doc(docRef,updatedDoc,options) {
  return setDoc(docRef,db_clone(updatedDoc));
}


export function fs_get_collection(context,{collection_path,options}) {
  let res = {};
  let opt = (options === undefined) ? {} : options;
  let path_tokens = db_tokens(collection_path,'/');
  let cbf = (opt.on_receive_document === undefined) ? function() { } : opt.on_receive_document;
  let collection_and_document_name = db_collection_and_document_name(collection_path,context);
  let copy_options = Object.assign({},{...opt,...collection_and_document_name});
  let live_bind = (opt.live !== false);
  let collection_name = path_tokens.pop();
  let collection_parent_path = path_tokens.join('/');
  let collection_parent = db_recurse_to_object(context,collection_parent_path);
  let collectionRef = db_get_collection_ref(collection_path);
  res.collection_ref = collectionRef;
  let conditions = (opt.where === undefined) ? [['deleted','==',false]] : opt.where;
  for (let q = 0 ; q < conditions.length ; q ++ ) {
    let qq = conditions[q];
    if (typeof qq === 'function') {
      let query_res = qq(options.record);
      collectionRef = query(collectionRef,where(query_res[0],query_res[1],query_res[2]))
    }
    else 
    {
      collectionRef = query(collectionRef,where(qq[0],qq[1],qq[2]));
    }
  }
  
  if (live_bind === false) {
    collectionRef.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        copy_options.change = {type:'added'};
        let handle_res = handle_doc(context,doc,collection_path,doc.id,copy_options);
        cbf(handle_res,copy_options);
      })
    }).catch(function(err) {
    })
  }
  else
  {
    if (collection_parent.collection_state === undefined) {
      collection_parent.collection_state = {};
    }

    if (collection_parent.collection_state[collection_name] === undefined) {
      collection_parent.collection_state[collection_name] = {}
    }

      let snapshot_unsubscribe = collectionRef;
      onSnapshot(collectionRef, {includeMetadataChanges:true},function(querySnapshot) {
        querySnapshot.docChanges().forEach(function(change) {
          let doc = change.doc;
          let version = get_version(doc);
          copy_options.change = {type:change.type};
          copy_options.version = version;
          let handle_res = handle_doc(context,doc,collection_path,doc.id,copy_options);
          cbf(handle_res,copy_options)
        })
      })
      collection_parent.collection_state[collection_name].unsubscribe = snapshot_unsubscribe;
      collection_parent.collection_state[collection_name].bound = true;
      collection_parent.collection_state[collection_name].unbind = function() {
        if (collection_parent.collection_state[collection_name].bound === true ) {
          collection_parent.collection_state[collection_name].bound = false;
          collection_parent.collection_state[collection_name].unsubscribe();
          collection_parent.collection_state[collection_name].unsubscribe = undefined;
          collection_parent.collection_state[collection_name].bind = function() {
            context.dispatch('db_get_collection',{collection_path,options});
          }
        }

      }
      res.unsubscribe = snapshot_unsubscribe;
      // res.unsubscribe = collection_parent.collection_state[collection_name].unsubscribe;
    }
return res;
}


//------------------------------------------------------------------------------
// UPDATE FIELD
//------------------------------------------------------------------------------

export function fs_update_field(context,{record,field,value,options}) {
  let val = (value === undefined) ? _.get(record,field) : value;
  let collection_path = record.collection_path;
  let id = record.id;
  let opts = (options === undefined) ? {} : options;
  let cbf  = (opts.on_success === undefined ) ? function() { } : opts.on_success;
  let err  = (opts.on_error === undefined ) ? function() { } : opts.on_error;
  let object = {};
  object[field] = val;
  let cln = db_clone(object);
  if (context.getters.db_active_user_id !== undefined ) {
    cln.created_by_email = context.getters.db_active_user_email;
    cln.created_by_uid = context.getters.db_active_user_id;
  }
  cln.updated_timestamp = serverTimestamp();
  let addRef = db_get_document_ref(db_path(collection_path),id);
  db_update_doc(addRef,cln).then(cbf).catch(err);
  opts.type = opts.change ?? {}; 
  opts.type.change = 'modified';
  db_update_state(context,collection_path,id,cln,opts);
  return addRef;
}

//------------------------------------------------------------------------------
//  FS GET DOCUMENT
//------------------------------------------------------------------------------

export function fs_get_document(context,{collection_path,id,options}) {
  let res = {};
  let opt = (options === undefined) ? {} : options;
  let collection_and_document_name = db_collection_and_document_name(collection_path,context);
  let copy_options = Object.assign({},{...opt,...collection_and_document_name});
  let cbf = (opt.on_success === undefined) ? function() { } : opt.on_success;
  let live_bind = (opt.live !== false);
  let docRef = db_get_document_ref(collection_path,id);
  res.document_ref = docRef;
  if (live_bind === false) {
    docRef.get().then(function(doc) {
      copy_options.change = {type:'added'};
      let allocated_doc = handle_doc(context,doc,collection_path,id,copy_options);
      cbf(allocated_doc,options)
    })
  }
  else
  {
    let snapshot_unsubscribe = onSnapshot(docRef,{ includeMetadataChanges: true },function(doc) {
      copy_options.change = {type:'added'};
      let allocated_doc = handle_doc(context,doc,collection_path,id,copy_options)
      cbf(allocated_doc,options);
    });
    res.unsubscribe = snapshot_unsubscribe;
  }
  return res;
}


//------------------------------------------------------------------------------
// FS FLAG DELETED RECORD
//------------------------------------------------------------------------------

export function fs_flag_delete_document(context,{collection_path,id,options}) {
  let opts = (options === undefined) ? {} : options;
  let cbf  = (opts.on_success === undefined ) ? function() { } : opts.on_success;
  let err  = (opts.on_error   === undefined ) ? function() { } : opts.on_error;  
  let cln  = {deleted:true};
  if (context.state !== undefined && context.state.user !== undefined && context.state.user.email !== undefined) {
    cln.deleted_by_email = context.state.user.email;
  }
  cln.deleted_timestamp = serverTimestamp();
  let addRef = db_get_document_ref(db_path(collection_path),id);
  db_update_doc(addRef,cln).then(cbf).catch(err);
  db_delete_state(context,collection_path,id);
  return addRef;
}

//------------------------------------------------------------------------------
// PERMANENTLY DELETE A RECORD FROM FIRESTORE
//------------------------------------------------------------------------------


export function fs_delete_document(context,{collection_path,id,options}) {
  let collection_and_doc = db_collection_and_document_name(collection_path,context);
  let definition = collection_and_doc.definition;
  if (definition.delete_mode === 'permanent') {
    let opts = (options === undefined) ? {} : options;
    let cbf  = (opts.on_success === undefined ) ? function() { } : opts.on_success;
    let err  = (opts.on_error   === undefined ) ? function() { } : opts.on_error;  
    let addRef = db_get_document_ref(db_path(collection_path),id).delete().then(cbf).catch(err);
    db_delete_state(context,collection_path,id);
    return addRef;  
  } else 
  if (definition.delete_mode === 'flag') {
    return fs_flag_delete_document(context,{collection_path,id,options})
  }
}


//------------------------------------------------------------------------------
// CREATE RECORD
//------------------------------------------------------------------------------

export function fs_create_document(context,{record,options}) {
  let id = record.id;
  let opts = (options === undefined) ? {} : options;
  let merge = (opts.merge === true);
  let cbf  = (opts.on_success === undefined ) ? function() { } : opts.on_success;
  let err  = (opts.on_error === undefined ) ? function() { } : opts.on_error;
  let cln = db_clone(record);
  opts.change = opts.change ?? {}; 
  opts.change.type = 'added';
  let collection_path = record.collection_path;
  cln.deleted = false;
  cln.selected = true;
  if (context.getters.db_active_user_id !== undefined ) {
    cln.created_by_email = context.getters.db_active_user_email;
    cln.created_by_uid = context.getters.db_active_user_id;
  }
  cln.created_timestamp = serverTimestamp();
  cln.updated_timestamp = serverTimestamp();
  let addRef;
  if (id === 'AUTO') {
      addRef = db_get_collection_ref(fs_path(collection_path)).add(cln).then(function(docRef) {
        cln.id = docRef.id;
        db_update_state(context,collection_path,cln.id,cln,opts);
        cbf(cln);
      }
    ).catch(err);

  }
  else
  {
    
    addRef = db_get_document_ref(db_path(collection_path),id);
    db_create_doc(addRef,cln,{merge:true});
    // updateDoc(addRef,cln,{merge:merge}).then(cbf).catch(err);
    db_update_state(context,collection_path,id,cln,opts);
  }

  return addRef;
}

//------------------------------------------------------------------------------
//  UPDATE FIELDS OF A RECORD IN FIREBASE
//------------------------------------------------------------------------------


export function fs_update_user(context,user,options) {
  let opts = options === undefined ? {} : options;
  let collection_path = user.collection_path;
  let id = user.id;
  let cbf = opts.on_success === undefined ? function () { } : opts.on_success;
  let addRef = db_get_document_ref(db_path(collection_path),id);
  db_update_doc(addRef,user).then(function(data) {
    cbf(data,opts);
  });
}

export function fs_update_document(context,{record,fields,options}) {
  let opts = (options === undefined) ? {} : options;
  if (opts.definition === undefined) {
    let collection_and_doc = db_collection_and_document_name(record.collection_path,context);
    opts.definition = collection_and_doc.definition;
   }
   let collection_path = record.collection_path;
   let id = record.id;
   let cbf  = (opts.on_success === undefined ) ? function() { } : opts.on_success;
   let object = {};
   
   for (let f = 0 ; f < fields.length ; f ++ ) {
     let field = fields[f];
     let field_name = field.name;
     let input_value = _.get(record,field_name);
     if (input_value !== null && input_value !== undefined) {
       object[field_name] = input_value;
      }
    }

  let cln = db_clone(object);

  if (context.getters.db_active_user_id !== undefined ) {
    cln.created_by_email = context.getters.db_active_user_email;
    cln.created_by_uid = context.getters.db_active_user_id;
  }
  cln.updated_timestamp = serverTimestamp();
  let addRef = db_get_document_ref(db_path(collection_path),id).update(cln).then(function(data) {
    cbf(data,opts);
  });
  opts.change = opts.change ?? {};
  opts.change.type = 'modified';
  db_update_state(context,collection_path,id,cln,opts);
  return addRef;
}

//------------------------------------------------------------------------------
//                                INTERNAL FUNCTIONS                           
//------------------------------------------------------------------------------

function get_version(doc) {
  let version_fields =  Object.keys(doc).filter(function(k) { 
    return ( doc[k] && doc[k].version && doc[k].version.timestamp && doc[k].version.timestamp.seconds && doc[k].version.timestamp.nanoseconds )
  });
  if (version_fields.length === 0) {
    return Math.floor(Math.random() * 1000000000 * 1000000000);
  }
  else 
  {
    let field = version_fields[0];
    return doc[field].version.timestamp.seconds  * 1000000000 +  doc[field].version.timestamp.nanoseconds
  }
}

//------------------------------------------------------------------------------

function handle_doc(context,doc,collection_path,document_id,options) {
  if (options.change.type === "removed") {
    db_delete_state(context,collection_path,document_id);
  } else 
  if (doc.exists) {
    if (!(doc.metadata.hasPendingWrites === true && options.change.type === 'modified')) {
      let id = doc.id;
      let data = doc.data();
      let res = db_assign_document_to_state(context,data,collection_path,id,options);
      return res;
    }
    else
    if (options.change.type === "removed") {
      
      return undefined
    }
    else 
    {
      return undefined;
    }
  }
}

//------------------------------------------------------------------------------
// EXPORTS
//------------------------------------------------------------------------------

const fs_utils = {
  fs_get_collection,
  fs_update_field,
  fs_get_document,
  fs_flag_delete_document,
  fs_delete_document,
  fs_create_document,
  fs_update_document,
}

//------------------------------------------------------------------------------

export default fs_utils;

//------------------------------------------------------------------------------

export const db_interface_firestore = {
  db_update_user:fs_update_user,
  db_get_collection:fs_get_collection,
  db_update_field:fs_update_field,
  db_get_document:fs_get_document,
  db_flag_delete_document:fs_flag_delete_document,
  db_delete_document:fs_delete_document,
  db_create_document:fs_create_document,
  db_update_document:fs_update_document,
  db_load_active_collections:db_load_active_collections
}

//------------------------------------------------------------------------------
