
import mitt from 'mitt'

const emitter = mitt();

export function onEvent(event,cb) {
  emitter.on(event,cb);
}

export function sendEvent(event,cb) {
  emitter.emit(event,cb)
};

export function offEvent(event,cb) {
  emitter.off(event,cb);
}

export const db_events = {
  onEvent,
  sendEvent,
  offEvent
}

