//---------------------------------------------------------------------

import {LCT,LCTDefinition,lct} from './lct.js';
import VueStore from 'vue-class-store';

//---------------------------------------------------------------------

@VueStore

class LCTResult extends LCT {
  constructor(data,store) {
    super(data,store)
  }
}

//---------------------------------------------------------------------

@VueStore
class LCTResultDefinition extends LCTDefinition{
  constructor(def,dbclass,context,name) {
    super(def,dbclass,context,name)
  }
}

//---------------------------------------------------------------------

export const log = {
  mixins:[lct],
  props:{
    product:{type:Object,required:true}
  }
}

//---------------------------------------------------------------------


//---------------------------------------------------------------------

const definition = { 
  definition:'result',
  views:{
    detail:{type:'item',record:'result'}
  },
  fields:{
    id:{
      field:'id',
      label:'Name',
      datatype:'id',
      description_field:'name',
      options:{},
      views:{item:false,list:true}
    },
    
    LOADCELLS:{
      field:'LOADCELLS',
      label:'Load Cells',
      datatype:'object',
      search:true,
      views:{item:true,edit:false,list:true,create:true}
    },

    TEMP_SENSORS:{
      field:'TEMP_SENSORS',
      label:'Temperature Sensors',
      write:true,
      datatype:'text',
      options:{},
      views:{item:true,edit:true,list:false,create:true}
    },


  },   

  collections:{
    // logs:{live:true,load:'load',where:[['deleted','==',false]]},
    // logs:{live:true,load:'load',where:[['deleted','==',false],['id','==','latest']]},
    logs:{live:true,load:'active',where:[['deleted','==',false]]},
  }
  

 }

//---------------------------------------------------------------------


export function Definition(context,name) { 
  return new LCTResultDefinition(definition,LCTResult,context,name)
} 
