<template>
    <div  class="lct-create-test-container">
    <input type="text" v-bind="testName">
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

	div.container{

  }

</style>


