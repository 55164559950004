<template>
    <div class="lct-tests-container">
    <router-link :to="{ name: 'createTest'}"> Create a new Test </router-link>

      <template v-for="test in tests" :key="test.id">  
      
        <router-link :to="{ name: 'test', params: { test: test,id:test.id } }"> {{test.name}} </router-link>

        
      </template>
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {
  },
  data() {
    return {
      
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState(['tests']),
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

div.lct-tests-container{
  
}

</style>


