<template>
  <nav>
    <router-link to="/">Home</router-link> | 
    <router-link to="/tests">Tests</router-link> | 
    <router-link to="/latest">Live</router-link>
  </nav>
  <router-view/>
</template>


<script>

import {mapState,mapActions} from "vuex";

function getDateStringCCYYMMDD(date = new Date()) {
  const year = date.getFullYear(); // Get the year (CCYY format)
  
  // Get the month and pad it with leading zero if necessary (to ensure MM format)
  const month = String(date.getMonth() + 1).padStart(2, '0');
  
  // Get the day and pad it with leading zero if necessary (to ensure DD format)
  const day = String(date.getDate()).padStart(2, '0');

  // Concatenate them into CCYYMMDD format
  return `${year}${month}${day}`;
}

// Example usage:
const dateString = getDateStringCCYYMMDD();

export default {
  components: {},
  props: {
  },
  data() {
    return {

    }
  },
  created() {
    
  },
  mounted() {
    let date = getDateStringCCYYMMDD();
    // date = '20241025';
    let self = this;
    setTimeout(() => {
      self.results[date].activate();
    }, 5000);
  },
  computed:{
  	...mapState(['results']),
  },
  methods:{
  	...mapActions(['db_load_definitions','user_authenticated'])
  }
 }
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: 'Open24Display';
  src: url('@/assets/fonts/Open24DisplaySt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
